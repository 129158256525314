import axios from 'axios';

// export const serverPoint = 'http://localhost:1337';
export const serverPoint = 'https://api.rishat-tour.ru';

export const fetchConcerts = () => {
  return axios.get(`${serverPoint}/concerts?_sort=date:ASC,city:ASC`)
}

export const fetchArtists = () => {
  return axios.get(`${serverPoint}/artists`)
}

export const fetchArtist = (nickname) => {
  return axios.get(`${serverPoint}/artists?nickname=${nickname}`)
}

export const fetchAbout = async () => {
  const data = await axios.get(`${serverPoint}/abouts`)
  if (data && data.data && Array.isArray(data.data) && data.data.length > 0) {
    const about = data.data[0]
    return {
      title: about.title,
      text: about.text,
      imgUrl: (about.image && about.image[0].url) ? `${serverPoint}${about.image[0].url}` : 'photo.jpg'
    }
  }
  return {
    title: 'Ришат Тухватуллин',
    text: 'Лауреат I степени Международного фестиваля татарской песни имени Рашита Вагапова Ришат Тухватуллин – суперпопулярный исполнитель на татаро-башкирской эстраде. ',
    imgUtl: 'photo.jpg'
  }
}

export const fetchYoutubeVideos = () => {
  return axios.get(`${serverPoint}/youtube-links`)
}

export const fetchAppleLink = () => {
  return axios.get(`${serverPoint}/apple-music-albums`)
}

export const fetchYandexLink = () => {
  return axios.get(`${serverPoint}/yandex-music-albums`)
}
