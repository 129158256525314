import React, { useRef, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as AppleIcon } from 'assets/svg/apple-white.svg';
import { ReactComponent as VkIcon } from 'assets/svg/VK.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as RutubeIcon } from 'assets/svg/rutube.svg';
import { ReactComponent as YoutubeIcon } from 'assets/svg/youtube.svg';
// import { ReactComponent as SpotifyIcon } from 'assets/svg/spotify.svg';
// import { ReactComponent as TiktokIcon } from 'assets/svg/tiktok.svg';


export default function Header() {
  let location = useLocation();
  const [pathname, _setPathname] = useState(location.pathname)
  const pathnameRef = useRef(pathname);
  const setPathname = data => {
    pathnameRef.current = data;
    _setPathname(data);
  };

  const checkHeaderScroll = function() {
    const headerNameEl = document.querySelector('.header__name');
    const headerEl = document.querySelector('.header');
    headerNameEl.classList.remove('header__name--hide');
    headerEl.classList.remove('header--fading')
    if (pathnameRef.current === '/') {
      if (window.scrollY < 220) {
        headerNameEl.classList.add('header__name--hide');
        headerEl.classList.add('header--fading')
      }
    }
  }

  useEffect(() => {
    setPathname(location.pathname)
    checkHeaderScroll();
    window.addEventListener('scroll', checkHeaderScroll);
  }, [location.pathname])


  return (
    <div className={`header ${(pathnameRef.current === '/') ? 'header--fading' : ''}`}>
      <div className="header__left">
        {/* Menu */}
        <div className="header__menu">
          <Link to="/concerts">
            <div> Концерты </div>
          </Link>
          <Link to="/music">
            <div> Музыка </div>
          </Link>
        </div>
      </div>

      {/* Rishat Tukhvatullin */}
      <Link className="header__name" to="/">
        Ришат Тухватуллин
      </Link>

      {/* social links */}
      <div className="header__links">
        {/* 
        <a className="header__link header__link-tiktok" rel="noopener noreferrer" href="https://vm.tiktok.com/ZSxbno9F/" target="_blank">
          <TiktokIcon />
        </a>
        <a className="header__link header__link-spotify" rel="noopener noreferrer" href="https://open.spotify.com/artist/10MgajZ7vRn9LVChqjykmj" target="_blank">
          <SpotifyIcon />
        </a>
        <a className="header__link header__link-apple" rel="noopener noreferrer" href="https://music.apple.com/ru/artist/%D1%80%D0%B8%D1%88%D0%B0%D1%82-%D1%82%D1%83%D1%85%D0%B2%D0%B0%D1%82%D1%83%D0%BB%D0%BB%D0%B8%D0%BD/1529902345" target="_blank">
          <AppleIcon />
        </a>
        */}
        <a className="header__link header__link-vk" rel="noopener noreferrer" href="https://vk.com/rishat_tukhvatullin_rt" target="_blank">
          <VkIcon />
        </a>
        <a className="header__link header__link-rutube" rel="noopener noreferrer" href="https://rutube.ru/channel/46152335/" target="_blank">
          <RutubeIcon />
        </a>
        <a className="header__link header__link-youtube" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC09mFevxJZCYyTve3yFffmQ" target="_blank">
          <YoutubeIcon />
        </a>
        <a className="header__link header__link-instagram" rel="noopener noreferrer" href="https://www.instagram.com/rishat_thv/" target="_blank">
          <InstagramIcon />
        </a>
      </div>
    </div>
  )
}
