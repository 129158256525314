import React, { useState, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import './style/main.sass';
import Home from 'pages/Home';
import Music from 'pages/Music';
import Concerts from 'pages/Concerts';
import ArtistConcerts from 'pages/ArtistConcerts';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Hamburger from 'components/Hamburger';
import Fallback from 'components/Fallback';
const Footer = lazy(() => import('components/Footer'));

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="App">
      <Header scrollActivation={true}/>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Hamburger sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="content">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/concerts" component={Concerts} />
          <Route exact path="/music" component={Music} />
          <Route path="/:name" component={ArtistConcerts} />
        </Switch>
      </div>
      <Suspense fallback={<Fallback />}>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
