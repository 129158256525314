import React, { useState, useEffect } from 'react';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as LocationIcon } from 'assets/svg/location.svg';
import Fallback from 'components/Fallback';
import { fetchConcerts } from 'api';

export default function ConcertsComp({initialConcerts}) {
  const [concerts, setConcerts] = useState([])
  const [concertMonths, setConcertMonths] = useState([])
  const [loading, setLoading] = useState(false)
  // const apiKey = 'ea7ee317-96ac-c32f-ff8c-11c358ca5afb';

  const getDate = (dateStr) => {
    let firstDateStr = dateStr.split('-')[0];
    firstDateStr = firstDateStr.replace(/\s/g, '');
    const parts = firstDateStr.split('.');
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      let concerts;
      if (initialConcerts) {
        concerts = initialConcerts
      } else {
        const data = await fetchConcerts();
        if (data && data.data && Array.isArray(data.data)) {
          concerts = data.data
        } else {
          return
        }
      }
      // sort by date
      const concertsWithDates = [];
      concerts.forEach(c => {
        const newConcert = c;
        newConcert.dateObj = getDate(c.date);
        concertsWithDates.push(newConcert)
      });
      concertsWithDates.sort(function(a, b) {
        return a.dateObj - b.dateObj;
      });
      // divide by year-months
      const months = {}
      concertsWithDates.forEach(c => {
        const month = c.dateObj.getMonth();
        const year = c.dateObj.getFullYear();
        const key = `${year}-${month}`;
        if (!months[key]) {
          months[key] = [];
        }
        months[key].push(c);
        // months[key].push(c);
      })
      const monthsArray = Object.keys(months).map(key => {
        return {
          year: key.split('-')[0],
          month: key.split('-')[1],
          concerts: months[key]
        }
      })
      console.log('months', months)
      // setConcerts(concertsWithDates);
      setConcertMonths(monthsArray);
      setLoading(false)
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ConcertMonth({ data }) {
    const { year, month, concerts } = data;
    const date = new Date(year, month, 1);
    let dateStr = date.toLocaleDateString('ru', { month: 'long', year: 'numeric' });
    // first char to uppercase
    if (dateStr[0]) {
      // dateStr[0] = dateStr[0].toUpperCase();
      dateStr = dateStr[0].toUpperCase() + dateStr.slice(1);
    }
    // if (dateStr[0]) {
    //   dateStr[0] = dateStr[0].toUpperCase();
    // }
    return (
      <div className="concerts__month">
        <div className="concerts__month-title">
          { dateStr }
        </div>
        {/*
        <div className="concert__month-year">
          { year }
        </div>
        <div className="concert__month-month">
          { month }
        </div>
        */}
        <div className="concerts__month-concerts">
          { concerts?.map(c => <Concert key={c.id} data={c} />) }
        </div>
      </div>
    )
  }

  function Concert({data}) {
    const { date, dateObj, time, city, place, price, link, pushkinskayaLink, openInANewPage } = data;
    let dateStr
    if (date.includes('-')) {
      const firstDateStr = date.split('-')[0];
      const secondDateStr = date.split('-')[1];
      const parts = firstDateStr.split('.');
      const firstDate = new Date(parts[2], parts[1] - 1, parts[0]);
      const parts2 = secondDateStr.split('.');
      const secondDate = new Date(parts2[2], parts2[1] - 1, parts2[0]);
      dateStr = firstDate.toLocaleDateString('ru', { day: 'numeric', month: 'long' }) + ' - ' + secondDate.toLocaleDateString('ru', { day: 'numeric', month: 'long' });
    } else {
      dateStr = dateObj.toLocaleDateString('ru', { day: 'numeric', month: 'long' });
    }

    const linkWidget = link;

    return (
      <div className="concert">
        {/* Date */}
        <div className="concert__date-container">
          <CalendarIcon className="concert__date-icon"/>
          <div className="concert__date">
            <div className="concert__date-dmy">
              { dateStr }
            </div>
            <div className="concert__date-time">
              { time }
            </div>
          </div>
        </div>
        {/* Place */}
        <div className="concert__location-container">
          <LocationIcon className="concert__location-icon"/>
          <div className="concert__location">
            <div className="concert__location-city">
              { city }
            </div>
            <div className="concert__location-place">
              { place }
            </div>
          </div>
        </div>
        {/* Cost */}
        <div className="concert__price">
          <div className="concert__price-label"> Цена </div>
          <div className="concert__price-amount">
            { price }
          </div>
        </div>
        { !!pushkinskayaLink && 
            (<a target={openInANewPage ? '_blank' : '_self'} className="concert__btn knopka" href={pushkinskayaLink}
            // onClick={() => {
            //   window.kassirWidget.summon({url: linkWidget})}
            // }
            >
              Купить по Пушкинской карте
            </a>)
        }
        {/* Buy */}
          <a target={openInANewPage ? '_blank' : '_self'} className="concert__btn" href={linkWidget}
          // onClick={() => {
          //   window.kassirWidget.summon({url: linkWidget})}
          // }
          >
            { !!pushkinskayaLink ? 'Купить по обычной карте' : 'Купить билет'}
          </a>
        {/* </div> */}
      </div>
    )
  }
  return (
    <div className="concerts">
      {/*
      <div className="concerts__title"> Концерты</div>
      */}
      { loading ? <Fallback /> : ''}
      { concertMonths && concertMonths.map((c, i) => <ConcertMonth key={i} data={c} />) }
      {/* concerts && concerts.map((c, i) => <Concert key={i} data={c} />) */}
    </div>
  )
}
