import React, { useState, useEffect } from 'react';
import ConcertsComp from 'components/ConcertsComp'
import { getConcertDateFromApiStr } from 'utils/date';
import { fetchArtist } from 'api';
import { useParams } from 'react-router-dom';

export default function ArtistConcerts() {
  const [loading, setLoading] = useState(false)
  const [artist, setArtist] = useState(false)
  const { name } = useParams()

  console.log('hey')
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await fetchArtist(name);
      const a = data.data?.[0]
      console.log('fetch artist', a)
      // const concertsWithDates = [];
      // a.concerts.forEach(c => {
      //   const newConcert = c;
      //   newConcert.dateObj = getConcertDateFromApiStr(c.date);
      //   concertsWithDates.push(newConcert)
      // });
      // concertsWithDates.sort(function(a, b) {
      //   return a.dateObj - b.dateObj;
      // });
      setArtist(a)
      setLoading(false)
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="concerts-page">
      <div className="concerts-page__title">
        { artist?.fullName || 'Концерты' }
      </div>
      {artist?.concerts &&
        <ConcertsComp initialConcerts={artist?.concerts} />
      }
    </div>
  )
}
